<template>
  <div>
    <vue-element-loading
      :active="appLoading"
      :is-full-screen="true"
      background-color="#FFFFFF"
      color="#68D389"
      spinner="bar-fade-scale"
    />
    <v-snackbar v-model="showSnackBar" color="#68D389" right :timeout="timeout">
      <v-layout wrap justify-center>
        <v-flex text-left align-self-center>{{ msg }}</v-flex>
        <v-flex text-right>
          <v-btn small :ripple="false" text @click="showSnackBar = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-flex>
      </v-layout>
    </v-snackbar>
    <v-layout v-if="ServerError" wrap justify-center>
      <v-flex px-5 pt-5 xs12 sm12 md12 lg12 xl12 text-center>
        <ServerError />
      </v-flex>
    </v-layout>
    <v-layout v-else wrap justify-start>
      <v-flex xs6 text-left>
        <span style="color: #000; font-size: 20px; font-family: poppinsbold"
          >Payment Report</span
        >
      </v-flex>
      <!-- <v-flex
        text-left
        lg3
        style="color: #000; font-size: 20px; font-family: poppinsbold"
      >
        <v-btn
          dark
          depressed
          color="#68D389"
          @click="filterdialog = true"
          value="#68D389"
          hide-details
        >
          <span>FILTERS </span>
        </v-btn>
      </v-flex> -->
      <v-flex xs3>
        <v-text-field
          class="rounded-xl"
          prepend-inner-icon="mdi-magnify"
          outlined
          color="#68D389"
          solo
          dense
          label="Search with ticket"
          v-model="appSearch"
          @keyup.enter="getData"
        >
        </v-text-field>
      </v-flex>
      <v-flex xs12>
        <v-layout wrap justify-start>
          <v-flex xs12 sm6 md3 pa-5>
            <v-menu
              ref="menu1"
              v-model="menu1"
              :close-on-content-click="false"
              transition="scale-transition"
              offset-y
              max-width="290px"
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="dates"
                  label="Date"
                  outlined
                  dense
                  v-bind="attrs"
                  v-on="on"
                  clearable
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="dates"
                no-title
                @input="(menu1 = false), (toDate = null), (fromDate = null)"
              ></v-date-picker>
            </v-menu>
          </v-flex>
          <v-flex xs12 sm6 md3 pa-5>
            <v-menu
              ref="menu3"
              v-model="menu3"
              :close-on-content-click="false"
              transition="scale-transition"
              offset-y
              max-width="290px"
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="fromDate"
                  label="Transaction from date"
                  outlined
                  dense
                  v-bind="attrs"
                  v-on="on"
                  clearable
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="fromDate"
                no-title
                @input="(menu3 = false), (dates = null)"
              ></v-date-picker>
            </v-menu>
          </v-flex>
          <v-flex xs12 sm6 md3 pa-5>
            <v-menu
              ref="menu2"
              v-model="menu2"
              :close-on-content-click="false"
              transition="scale-transition"
              offset-y
              max-width="290px"
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="toDate"
                  label="Transaction to date"
                  outlined
                  dense
                  v-bind="attrs"
                  v-on="on"
                  clearable
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="toDate"
                no-title
                @input="(menu2 = false), (dates = null)"
              ></v-date-picker>
            </v-menu>
          </v-flex>
          <v-flex xs12 sm6 md3 lg3 xl4 pa-5>
            <v-select
              v-model="filter"
              :items="filterStatus"
              item-text="name"
              item-value="value"
              item-color="#8d8d8d"
              color="#8d8d8d"
              outlined
              dense
              label="Status"
            >
            </v-select>
          </v-flex>

          <!-- <v-flex v-if="programme" xs12 sm6 md3 lg3 xl3 pa-5>
            <v-select
              v-model="slotDetail"
              :items="slotItems"
              item-text="startTime"
              item-value="_id"
              item-color="#8d8d8d"
              color="#8d8d8d"
              outlined
              dense
              label="Slots"
            >
              <template v-slot:selection="{ item }">
                <span>
                   {{ item.startTime}} -
                  {{ item.endTime }}
                </span>
              </template>
              <template v-slot:item="{ item }">
                <span>
                 {{ item.startTime}} -
                  {{ item.endTime }}
                </span>
              </template>
            </v-select>
          </v-flex> -->
          <!--           
      <v-flex xs12 align-self-end>
        <v-layout wrap justify-end> -->
          <v-flex md4>
            <v-spacer></v-spacer>
          </v-flex>
          <v-flex md8 text-right align-self-start>
            <v-layout wrap justify-end pt-0 pb-4>
              <v-flex xs12 sm6 md6 lg6 xl6>
                <a
                  target="_blank"
                  :href="
                    'https://api.wayanadwildlifesanctuary.com/get/daily/report?dt=' +
                    dates
                  "
                  class="downlink"
                >
                <span
                  style="
                    font-size: 16px;
                    font-family: poppinssemibold;
                    color: #68d389;
                    cursor: pointer;
                  "
                >
                  Download Daily Payment Abstract</span
                >
                </a>
              </v-flex>
              <v-flex xs12 sm6 md3 lg3 xl3>
                <a
                  target="_blank"
                  :href="
                    'https://api.wayanadwildlifesanctuary.com/report/bookingreportpdf?date=' +
                    dates +
                    '&status=' +
                    filter +
                    '&fromDate=' +
                    fromDate +
                    '&toDate=' +
                    toDate
                  "
                  class="downlink"
                >
                  <span
                    style="
                      font-size: 16px;
                      font-family: poppinssemibold;
                      color: #68d389;
                    "
                  >
                    Download Pdf</span
                  >
                </a>
              </v-flex>
              <v-flex xs12 sm6 md3 lg3 xl3 align-self-end>
                <download-excel :data="json_data" :fields="json_fields">
                  <span
                    style="
                      font-size: 16px;
                      font-family: poppinssemibold;
                      color: #68d389;
                      cursor: pointer;
                    "
                  >
                    Download Excel</span
                  >
                </download-excel>
              </v-flex>
            </v-layout>
          </v-flex>
          <!-- </v-layout>
      </v-flex> -->
        </v-layout>
      </v-flex>
      <!-- <v-flex xs12>
        <v-layout wrap justify-center pt-4>
          <v-flex>
            <v-tabs
              fixed-tabs
              v-model="tabValue"
              color="#68D389"
              centered
              slider-color="yellow"
            >
              <v-tab v-for="item in titleArray" :key="item.value">
                {{ item.title }}
              </v-tab>
            </v-tabs>
          </v-flex>
        </v-layout>
      </v-flex> -->

      <v-flex xs12>
        <v-layout wrap>
          <v-flex xs12 sm6 text-left>
            <span style="color: #000; font-size: 18px; font-family: poppinsbold"
              >Payments</span
            >
          </v-flex>
          <v-flex xs12 sm6 text-right>
            <span
              style="color: #000; font-size: 18px; font-family: poppinssemibold"
              >Total Amount:
              <b style="color: green">{{ totalAmt }} INR</b></span
            >
          </v-flex>
          <v-flex v-if="bookingData.length > 0" xs12 pt-4 text-left>
            <ProgramBooking
              :bookingData="bookingData"
              :dates="dates"
              :filter="filter"
              :currentPage="currentPage"
              :programme="programme"
              :pages="pages"
              @stepper="winStepper"
            />
          </v-flex>

          <v-flex v-else xs12 text-left pa-4>
            <span style="color: #000; font-size: 18px; font-family: poppinsbold"
              >Payments</span
            >
            <br />
            <span class="textField2"> No Payments Found !! </span>
          </v-flex>
        </v-layout>
      </v-flex>

      <!-- <v-flex xs12 v-if="tabValue == 1">
        <v-layout wrap>
          <v-flex v-if="reservationData.length > 0" xs12 pt-4 text-left>
            <span style="color: #000; font-size: 18px; font-family: poppinsbold"
              >Reservations</span
            >
            <Reservation
              :reservationData="reservationData"
              :fromDate="fromDate"
              :toDate="toDate"
              :programme="programme"
              :pages="pages"
              @stepper="winStepper"
            />
          </v-flex>
          <v-flex v-else xs12 text-left pa-4>
            <span style="color: #000; font-size: 18px; font-family: poppinsbold"
              >Reservations</span
            >
            <br />
            <span class="textField2"> No Reservations Found !! </span>
          </v-flex>
        </v-layout>
      </v-flex> -->
    </v-layout>
  </div>
</template>
<script>
import axios from "axios";
import ProgramBooking from "./paymentReport";
// import Reservation from "./reservation";
export default {
  components: {
    // Reservation,
    ProgramBooking,
  },
  data() {
    return {
      filterdialog: false,
      appLoading: false,
      showSnackBar: false,
      ServerError: false,
      timeout: 5000,
      msg: null,
      bookingData: [],
      reservationData: [],
      programme: null,
      slotDetail: null,
      programeData: [],
      details: [],
      slotDetails: [],
      guest: [],
      programs: [],
      slotItems: [],
      panel: [],
      disabled: false,
      selectDate: new Date(),
      dates: new Date(),
      toDate: new Date(),
      fromDate: new Date(),
      menu1: false,
      menu2: false,
      menu3: false,
      menu4: false,
      currentPage: 1,
      pages: 0,
      appSearch: null,

      json_fields: {
        "Transaction ID": "txnid",
        TicketNo: "ticket.ticketNo",
        TransactionDate: "init_date",
        PhoneNumber: "user.phone",
        Amount: "total",
        Status: "verificationStatus",
      },
      json_data: [],
      json_meta: [
        [
          {
            key: "charset",
            value: "utf-8",
          },
        ],
      ],
      date: new Date(),
      totalAmt: 0,
      tabValue: 0,
      titleArray: [
        { title: "BOOKINGS", value: "booking" },
        // { title: "RESERVATIONS", value: "reservation" },
      ],
      type: null,
      filter: null,
      filterStatus: [
        { name: "All", value: null },
        { name: "Verified", value: "Verified" },
        { name: "Not Verified", value: "Not Verified" },
      ],
    };
  },

  beforeMount() {
    // this.getExcel();
    // this.getData();

    // if (this.dateProp != "dateWisePrograms") this.date = null;
    // this.getData();
    this.getProgramme();

    if (this.fromDate) {
      this.fromDate = null;
      // this.fromDate.setDate(this.fromDate.getDate());
      // this.fromDate = this.fromDate.toISOString().substr(0, 10);
    }
    if (this.toDate) {
      this.toDate = null;
      // this.toDate.setDate(this.toDate.getDate());
      // this.toDate = this.toDate.toISOString().substr(0, 10);
    }
    if (this.dates) {
      this.dates.setDate(this.dates.getDate());
      this.dates = this.dates.toISOString().substr(0, 10);
    }
  },
  watch: {
    dates() {
      if (this.currentPage > 1) this.currentPage = 1;
      this.getData();
      // this.getExcel();
    },
    filter() {
      if (this.currentPage > 1) this.currentPage = 1;
      this.getData();
      // this.getExcel();
    },
    fromDate() {
      if (this.currentPage > 1) this.currentPage = 1;
      this.getData();
      this.getExcel();
      // this.checkDate();
    },
    toDate() {
      if (this.currentPage > 1) this.currentPage = 1;
      this.getData();
      this.getExcel();
      // this.checkDate();
    },

    programme() {
      this.getData();
      this.getSlot();
      this.getExcel();
    },
  },
  methods: {
    callSearch() {
      if (this.currentPage > 1) this.currentPage = 1;
      this.getData();
    },
    // checkDate() {
    //   let ndate= new Date().toISOString().substr(0, 10);
    //   if (this.toDate == ndate) {
    //     const tomorrow = new Date(this.toDate);
    //     tomorrow.setDate(tomorrow.getDate() + 20);
    //     this.toDate = tomorrow.toISOString().substr(0, 10);
    //   }
    // },
    winStepper(windowData) {
      if (windowData) {
        this.currentPage = windowData.currentPage;
        if (windowData.pages) this.pages = windowData.pages;
        this.type = windowData.type;
        this.getData();
      }
    },
    getData() {
      // console.log("Parent getData",this.currentPage);
      if (this.currentPage) {
        this.appLoading = true;
        axios({
          method: "GET",
          url: "/report/bookingreport",
          headers: {
            token: localStorage.getItem("token"),
          },
          params: {
            date: this.dates,
            fromDate: this.fromDate,
            toDate: this.toDate,
            programme: this.programme,
            slotDetail: this.slotDetail,
            status: this.filter,
            count: 12,
            page: this.currentPage,
            ticketNumber: this.appSearch,
          },
        })
          .then((response) => {
            if (response.data.status) {
              this.bookingData = response.data.data;
              this.totalAmt = response.data.payamentAmount;
              this.pages = response.data.pages;

              // this.excel = response.data.data;
              // this.json_data = this.excel;
              this.getExcel();
              // this.excel = response.data.data;
              // this.json_data = this.excel;
              this.appLoading = false;
            } else {
              this.msg = response.data.msg;
            }
          })
          .catch((err) => {
            this.appLoading = false;
            this.ServerError = true;
            console.log(err);
          });
      }
    },
    getExcel() {
      this.appLoading = true;
      axios({
        method: "GET",
        url: "/report/bookingreport",
        headers: {
          token: localStorage.getItem("token"),
        },
        params: {
          // date: this.dates,
          // fromDate: this.fromDate,
          // toDate: this.toDate,
          // programme: this.programme,
          // slotDetail: this.slotDetail,
          // status: this.filter,
          // count: 12,
          // page: this.currentPage,
        },
      })
        .then((response) => {
          if (response.data.status) {
            // for (var i = 0; i < response.data.data.length; i++) {
            //   response.data.data[i].bookingDate = this.formatDate(
            //     response.data.data[i].bookingDate
            //   );
            //   response.data.data[i].dob = this.formatDate(
            //     response.data.data[i].dob
            //   );
            // }
            this.excel = response.data.data;
            this.json_data = this.excel;
            this.appLoading = false;
          } else {
            this.msg = response.data.msg;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
    getProgramme() {
      this.appLoading = true;
      axios({
        url: "/programme/getlist",
        method: "GET",
        params: { programmestatus: "started" },
        headers: {
          token: localStorage.getItem("token"),
        },
      })
        .then((response) => {
          if (response.data.status) {
            this.appLoading = false;
            var temp = [
              {
                name: null,
                value: null,
              },
            ];
            temp = response.data.data;
            for (var i = 0; i < temp.length; i++) {
              temp[i].name = temp[i].progName;
              temp[i].value = temp[i]._id;
            }
            this.programs = temp;
            this.programs.unshift({ name: "All Programs", value: null });
          }
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
    getSlot() {
      this.appLoading = true;
      axios({
        url: "/programme/slots/getlist",
        method: "GET",
        headers: {
          token: localStorage.getItem("token"),
        },
        params: {
          programme: this.programme,
        },
      })
        .then((response) => {
          if (response.data.status) {
            this.appLoading = false;
            this.slotItems = response.data.data;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
    formatDate(item) {
      var dt = new Date(item);
      // var day = dt.getDate();
      var year = dt.getFullYear();
      dt = dt.toString();
      var strTime = dt.slice(4, 10) + " " + year;
      return strTime;
    },
  },
};
</script>
<style>
.head {
  font-family: poppinsregular;
  font-size: 14px;
}
.content {
  font-family: poppinsbold;
  font-size: 13px;
}

/* .cornerboxd >>> fieldset {
  border-color: rgba(250, 162, 0, 0.986);
  border-radius: 50px;
} */
</style>
